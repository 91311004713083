.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.homeContent {
  width: 100%;
  max-width: 500px;
}

.homePanel {
  display: flex;
  flex-direction: column;
}

.logo {
  margin: auto;
  max-width: 80%;
}
